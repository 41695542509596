import React from "react"
import Layout from "../components/layout"

export default ({  }) => {
    if (typeof window !== 'undefined') {
      window.location.replace("/jerseyharbours/cruise/");
    }
    return (
    <Layout>

    </Layout>
  )
}